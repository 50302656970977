import React from 'react'
import PageHeader from '../../PageHeader/pageheader'
import Improvement from '../servisedeatils/Improvement'
import { useTranslation } from 'react-i18next';

const ServiceImprovement = () => {
  const { t } = useTranslation()
  return (
    <>
    
    <PageHeader title={t('home')} subtitle={t('pageheaderservice')} common={t('process')}/>

        <Improvement/>
    </>
  )
}

export default ServiceImprovement