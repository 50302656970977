export const translationTr = {

    "__NAVBAR__":"Navbar için çeviri.",
    "language": "Seçenekler",
    "faq":"SSS",
    "workday":"Pazartesi - Cuma 08:00 / 19:00",
    "home":"Anasayfa",
    "about":"Hakkımızda",
    "services":"Hizmetler",
    "testimonial":"Referanslar",
    "contact":"İletişim",
    "collanytime":"İSTEDIĞINIZ ZAMAN ARAYIN",

    "__PAGEHEADER__":"Page headerlar için çeviri",
    "pagehometitle":"Anasayfa || EKSPER ENDUSTRIYEL",
    "pagetitleabout":"Hakkımızda || EKSPER ENDUSTRIYEL",
    "pageheaderabout":"Biz kimiz?",
    "pagetitleservice":"Hizmetler || EKSPER ENDUSTRIYEL",
    "pageheaderservice":"Yetkinliklerimiz",
    "pagetitletestimonial":"Referanslar || EKSPER ENDUSTRIYEL",
    "pageheadertertimonial":"Görüşler",
    "pagetitlecontact":"İletişim|| EKSPER ENDUSTRIYEL",
    "pageheadercontact":"Bize Ulaşın",

    "__FOOTER__":"Footer için çeviri",
    "footerArticle":"Müşterilerimizin ihtiyaçlarını anlayarak, özelleştirilmiş tasarım çözümleri sunarak, iş süreçlerini optimize etmelerini sağlamak bizim işimiz!",
    "fooDiscover":"Keşfedin",
    "fooabout":"Hakkımızda",
    "fooservice":"Hizmetlerimiz",
    "footestimonial":"Referanslar",
    "foofaq":"SSS",
    "foocontact":"İletişim",
    "fooCopyright":"© Copyright 2023 Eksper Endüstriyel. Her hakkı saklıdır!",

    "__SLIDER__":"Anasayfa için çeviri",
    "slidertitle":"Basit ve Güvenli Tasarım Süreci",
    "sliderdesc": "İhtiyaçlarınıza uygun çözümler üretiyoruz!",
    "slidertitle2":"Basit ve Güvenli Optimisazyon",
    "sliderdesc2": "Daha az ambalaj daha fazla ürün!",
    "slidertitle3":"Basit ve Güvenli İyileştirme",
    "sliderdesc3": "Ambalajlama operasyonlarının iyileştirilmesi",
    "slidertitle4":"Basit ve Güvenli Rotüş",
    "sliderdesc4": "Talep edilen şekilde ayıklamada öncüyüz!",

    "__CARD__":"Anasayfa giriş kart için çeviri",
    "Indcardtitle": "Endüstriyel Çözümler",
    "IncardDest": "Stok yönetimi, üretimden gönderime kadar şirketinizin ihtiyaçlarını tespit ediyor ve çözümler üretiyoruz.",
    "Indcardtitle2": "Ekolojik Gelişim",
    "IncardDest2": "İhtiyaç duyulan malzemeleri temin ederken sadece ekonomik değil ekolojik etkilerini de değerlendiriyoruz.",
    "Indcardtitle3": "Modern Teknoloji",
    "IncardDest3": "Yenilikçi fikirler ve yüksek teknolojili üretim araçlarımızla birlikte zamanın gerekliliklerini karşılıyoruz.",

    "__SERVICE__":"Anasayfa hizmetler için çeviri",
    "whypre": "Ne Sunuyoruz",
    "allservice": "Tüm Hizmetler",
    "allcatagory": "Tüm Kategoriler",

    "__SERVICE-CARD__":"Anasayfa hizmet card için çeviri",
    "packdesigner": "Ambalaj Tasarım",
    "packetüd": "Ambalaj Etüt",
    "process": "Üretim İyileştirmesi",
    "retouch": "Üretim Planlama",

    "__INFORM-CARD__":"Anasayfa bilgi ve card için çeviri",
    "informtake": "Hakkında Bilgi Alın",
    "informtitle": "Siz ihtiyacınızı söyleyin biz tasarlıyalım!",
    "informdesp": "Ambalaj içine konulan ürünü koruyan, en temiz ve en güvenilir koşullarda size ulaşmasını sağlayan, taşınmasını ve depolanmasını kolaylaştıran değerli bir malzemedir.",
    "inforcard": "Tüm Ambalajlarda istikrarlı fiyatlar",
    "inforcard2": "100% Kalite garantisi ve Başarı oranı",
    "inforcard3": "Her bütçeye uygun ödeme planı",

    "__ADVANTAGE__":"Anasayfa avantajlar için çeviri",
    "experience": "26 yıllık iş tecrübesi",
    "advantage": "Avantajlarımız",
    "advtitle": "Neden Bizi Seçmelisiniz?",
    "profteam": "Profesyonel Ekip",
    "teamdesc": "Deneyimli ve profesyonel bir ekip için,",
    "teamdesc2": "Hızlı ve ekonomik bir çözüm için," ,
    "teamdesc3": "Özgün ve ilerici bir tasarım için,",
    "teamdesc4": "Zamandan ve alandan kazanç için,",
    "ease": "Kazançlarımız",
    "easedesc": "•İsteklerinize en kısa sürede çözüm bulunduğu için ZAMAN",
    "easedesc2": "Profesyonel personele ulaşabildiğiniz için BİLGİ",
    "easedesc3": "Az zamanda verimli işler yapıldığı için PARA",
    "easedesc4": "Etüt çalışmaları ve tasarım için alan ihtiyacı en aza indiği için ALAN",
    "easedesc5": "En kısa sürede ve en kaliteli çözüme ulaşıldığı için KALİTE",
    "demandresponse": "Talebe yanıt",
    "counseling": "Danışmanlık",

    "__What-We-Offer__":"Anasayfa Ne Sunuyoruz? için çeviri",
    "whatwe": "Ne Sunuyoruz",
    "whatwetitle": "Stok yönetimi için endüstriyel tasarımlar",
    "whatwedesc": "Teknolojinin gücünü kullanarak, müşterilerimizi dünya çapında ambalaj süreçleri ile ilgili yenilikçi çözümler sunuyoruz.",
    "whatwequestitle": "Neden stok yönetimi?",
    "whatwequestdesc": "Sisteminize yapılan giriş ve çıkışlar kontrol altında tutulur.",
    "whatwequestitle2": "Neden Ambalaj iyileştirmeliyiz?",
    "whatwequestdesc2": "Alandan, Zamandan, İşçilikten ve brim parça maliyetinden kazanç sağlamak için",
    "whatwequestitle3": "Neden farklı ambalaj tipleri kullanmalıyız?",
    "whatwequestdesc3": "Parçanın özelliğine, ulaştığı müşterisine ve kat ettiği yola uygun ambalajlar tasarlanmalıdır!",


    "__COUNTER__":"Anasayfa counter için çeviri",
    "counter1": "Başarılı teslimat",
    "counter2": "Günlük destek",
    "counter3": "Mutlu Müşteriler",
    "counter4": "Personel Üyeleri",

    "__ABOUT__":"Hakkımızda için çeviri",
    "namework": "Eksper Endüstriyel",
    "aboutwork": "Şirket Ekibi",
    "aboutdesc": "1998 yılında ülkemizin en büyük otomobil üretim fabrikalarından birinde başlayan çalışma hayatı serüvenimiz 2023 yılında 25 yıllık bilgi ve birikim şirketimizin temel kuruluş dayanağı olmuştur." ,
    "aboutdesc2": "Üretim, kalite, ambalajlama, optimizasyon ve daha bir çok bilgiyi ve tecrübeyi biriktirirken, yıllar sonra bunları tüm sektöre yaygınlaştırma ve paylaşıma sunma heyecanı yaşadık. ",
    "aboutdesc3": "Nihayet 2023 yılında aynı amaca gönül vermiş çalışma arkadaşlarımızla beraber şirketimizi hayata geçirdik.",
    "aboutdesc4": "En başlarda çalışmalarımız kurum içinde kalsa da daha sonra yan sanayi ve diğer ana sanayi kuruşlarıyla bilgi paylaşımı ve çalışma toplantıları yapma fırsatı bulduk.",
    "aboutdesc5": "Şimdilerde her türlü ambalaj tasarımından, üretimine, üretim alanlarının yerleşiminden, çevrim zamanlarının iyileştirilmesine, ayıklama ve rötuş işlemlerinden, deniş aşırı ürün gönderiminde korozyon korumasına kadar geniş bir yelpazede sektöre hizmet etme tutkusundayız.",
    "aboutdesc6":  "Şirketlerin ambalajlama, yerleşim ve optimizasyon gibi yan işlerle vakit kaybetmeden ana üretim faaliyetlerine odaklanmasını sağlıyoruz. Her ürün gamında sunduğumuz bir çok seçenek ile en kaliteli ürünü en ucuza ve en kısa sürede temin edilmesini sağlıyoruz.",
    "aboutdesc7": "Kısacası biz yolunuzu açıyoruz siz ilerliyorsunuz.",
    "aboutexper": "Tecrübe",
    
    "__CONTACT__":"İletişim için çeviri",
    "uscontact": "Bizimle İletişime Geçin",
    "message": "Mesaj Bırakın",
    "contactdesc": "İhtiyacınıza yönelik bir tasarım fikrimi var? Biz ulaşın destek olalım!",
    "name":"Ad",
    "email": "Eposta",
    "phone": "Örnek '05559949808'",
    "subject": "Konu",
    "messagewrite": "Mesaj yaz...",
    "approval": "Form gönderebilmek için onay verin!",
    "formsend": "Mesaj Gönder",
    "emailwrite": "E-Posta Yazın",

    "__FAQ__":"SSS için çeviri",
    "sss": "Sıkça Sorulan Sorular",
    "faqtitle": "Ürünlerimizi neden ambalajlamalıyız?",
    "faqdesc": "Ürünü korumak, taşımak ve nihai müşteride aynı kaliteyi sağmalamak için",
    "faqtitle2": "Doğru ambalaj hangisidir?",
    "faqdesc2": "Ürünü koruyan, kat ettiği yola dayanaklı ve ürünü en son kullanıcıya ulaştıran ambalaj doğru ambalajdır!",
    "faqtitle3": "Ambalajlar neden çeşitlidir?",
    "faqdesc3": "Ürünü üretildiği kalite de korumak, taşımak ve müşteriye en uygun maliyette sunmak için çeşitli materyallerle ambalajlar yapılabilir. İşlenebilirliği kolay olması için Ahşap. Yüksek taşıma kapasitesi nedeniyle Metal. Uygun fiyat altarnatifi ve hafifliği sebebiyle Karton ambalajlar tasarlanabilir. Ayrıca farklı materyaller ile ambalajın içeriği desteklenebilir.",

    "__SERVICE-DESIGNER__":"Detaylı Ambalaj Tasarım için çeviri",
    "desginerdesc": "Ürünü üretildiği kalite de korumak, taşımak ve müşteriye en uygun maliyette sunmak için çeşitli materyallerle ambalajlar yapılabilir. İşlenebilirliği kolay olması için Ahşap. Yüksek taşıma kapasitesi nedeniyle Metal. Uygun fiyat altarnatifi ve hafifliği sebebiyle Karton ambalajlar tasarlanabilir. Ayrıca farklı materyaller ile ambalajın içeriği desteklenebilir.",
    "desginerpros": "Tasarım Süreçleri",
    "designerans": "Geri dönüşümlü veya tek kullanımlık ambalaj tasarımı.",
    "designerans2": "İhtiyaca özel istenen ebatlarda karton, ahşap, plastik veya metal ambalaj tasarımı.",
    "designerans3": "Catia çizimi.",
    "designerans4": "Numune üretimi.",

    "__SERVICE-ETUD__":"Detaylı Etüt için çeviri",
    "etüdDesc": "Ambalaj etüdü yapılırken kalitesi, maliyeti ve üretim süreleri dikkate alınır. Ambalajın etüdünde, Ambalajın ürüne uygun olması kaç kişi tarafından ne kadar sürede yapılıyor olması ve toplam maliye dikkate alınır. ",
    "etüdtitle": "Etüt Süreçleri",
    "etüdans": "Seri üretim fabrikaları için ambalaj etüdü.",
    "etüdans2": "Seri dışı parçalar için ambalaj etüdü.",
    "etüdans3": "Taşıma şekline göre (Kara-Hava-Deniz) ambalaj etüdü.",
    "etüdans4": "Üretim fişlerinin hazırlanması.",
    "etüdans5": "Ambalajlamanın maliyetlendirilmesi.",
    "etüdans6": "Operasyon etapları ve zaman etüdü.",
    "etüdans7": "Anti korozyon korumalı ambalajlama.",

    "__SERVICE-PROCESS__":"Detaylı Proses için çeviri",
    "processdesc": "Ürünün fabrikaya girişinden çıkışına kadar olan süreçte Alan, Zaman, Personel ve Maliyetler dikkate alınarak en iyi dengede üretim süreçleri belirlenir.",
    "processtitle": "İyileştirme Süreçleri",
    "processans": "Posta yerleşimi ve iyileştirmesi.",
    "processans2": "Üretim akışlarının düzenlenmesi.",
    "processans3": "Bant ikmal düzenlemesi.",
    "processans4": "İşlem sırası ve üretim şekli değişikliği.",
    "processans5": "Üretim maliyetlerinin düşürülmesi.",
    "processans6": "Ambalaj/kalite sorunlarının giderilmesi.",
    "processans7": "Taşıma değişikliğine (Kara-Hava-Deniz) göre ambalajlama şeklinin değiştirilmesi.",

    "__SERVICE-ROTUS__":"Detaylı Ayıklama & Rötüş için çeviri",
    "rotdesc": "Fason üretim, taşeron üretim müşteri siparişi ve imalatçı teslimatına göre gönderim planlaması yapılabilir. Sistem üzerinden veri alma (Firmada varsa SAP BI4 programıyla) ve analizinde destek verilebilir.",
    "rotprotitle": "Üretim Planlama Süreçleri",
    "rotans": "Üretim planlama ve lojistik destek.",
    "rotans2": "Belirlenmiş işlem akışına göre fason üretim.",
    "rotans3": "Belirlenmiş işlem akışına göre yerinde üretim.",
    "rotans4": "BI4 raporlama programıyla (şirkette varsa) raporlama ve planlamaya destek.",

    "__404__":"Detaylı 404 için çeviri",
    "page404title": "404 || Sayfa Bulunamadı",
    "404title": "Sayfa bulunamadı!",
    "lost": "Kaybolmuş gibi görünüyorsun.",
    "notavailble": "Aradığınız sayfa mevcut değil!",
    "backhome": "Ana Sayfaya Geri Dön"
  };

  export const translationEn = {

    "__NAVBAR__":"Navbar için çeviri.",
    "language": "Options",
    "faq":"FAQ",
    "workday":"Mon - Sat 8:00 AM - 7:00 PM",
    "home":"Home",
    "about":"About",
    "services":"Services",
    "testimonial":"Testimonial",
    "contact":"Contact",
    "collanytime":"COLL ANYTIME",

  "__PAGEHEADER__":"Page headerlar için çeviri",
    "pagehometitle":"Home || EXPER INDUSTRIAL",
    "pagetitleabout":"About Us || EXPER INDUSTRIAL",
    "pageheaderabout":"Who are us?",
    "pagetitleservice":"Services || EXPER INDUSTRIAL",
    "pageheaderservice":"Our Competencies",
    "pagetitletestimonial":"Testimonial || EXPER INDUSTRIAL",
    "pageheadertertimonial":"Opinions",
    "pagetitlecontact":"Contact | EXPER INDUSTRIAL",
    "pageheadercontact":"Contact Us",

  "__FOOTER__":"Footer için çeviri",
    "footerArticle":"It's our job to understand our customers' needs, provide customized design solutions, and enable them to optimize their business processes!",
    "fooDiscover":"Discover",
    "fooabout":"About Us",
    "fooservice":"Services",
    "footestimonial":"Testimonial",
    "foofaq":"FAQs",
    "foocontact":"Contact",
    "fooCopyright":"© Copyright 2023 Eksper Industrial. All rights reserved!",

    "__SLIDER__":"Anasayfa için çeviri",
    "slidertitle":"Simple and Safe Design Process",
    "sliderdesc": "We produce solutions suitable for your needs!",
    "slidertitle2":"Simple and Safe Optimization",
    "sliderdesc2": "Less packaging, more product!",
    "slidertitle3":"Simple and Safe Remediation",
    "sliderdesc3": "Improving packaging operations",
    "slidertitle4":"Simple and Safe Routing",
    "sliderdesc4": "We are pioneers in on-demand sorting!",

    "__CARD__":"Anasayfa giriş kart için çeviri",
    "Indcardtitle": "Industrial Solution",
    "IncardDest": "From stock management to production and shipping, we identify your company's needs and provide solutions.",
    "Indcardtitle2": "Ecological Development",
    "IncardDest2": "We evaluate not only the economic but also the ecological impact of the materials we procure.",
    "Indcardtitle3": "Modern Technology",
    "IncardDest3": "Together with innovative ideas and high-tech production tools, we meet the requirements of the times.",

    "__SERVICE__":"Anasayfa hizmetler için çeviri",
    "whypre": "What We Offer",
    "allservice": "All Services",
    "allcatagory": "All Categories",

    "__SERVICE-CARD__":"Anasayfa hizmet card için çeviri",
    "packdesigner": "Packaging Design",
    "packetüd": "Packaging Study",
    "process": "Process Improvement",
    "retouch": "Sorting & Routing",

    "__INFORM-CARD__":"Anasayfa bilgi ve card için çeviri",
    "informtake": "Get Information About",
    "informtitle": "You tell us what you need and we will design it!",
    "informdesp": "Packaging is a valuable material that protects the product inside, ensures that it reaches you in the cleanest and most reliable conditions, and facilitates its transportation and storage.",
    "inforcard": "Low Prices for All Packages",
    "inforcard2": "99.9% Success Rate Guarantee",
    "inforcard3": "Flexibility in Your Repayments",

    "__ADVANTAGE__":"Anasayfa avantajlar için çeviri",
    "experience": "26 Years of work experience",
    "advantage": "Our Advantages",
    "advtitle": "Why Choose Us?",
    "profteam": "Professional Team",
    "teamdesc": "For an experienced and professional team," ,
    "teamdesc2": "For a quick and economical solution," ,
    "teamdesc3": "For an original and progressive design,",
    "teamdesc4": "To save time and space,",
    "ease": "Payment facilities",
    "easedesc": "TIME for finding solutions to your requests as soon as possible",
    "easedesc2": "KNOWLEDGE for having access to professional staff",
    "easedesc3": "MONEY for doing productive work in less time",
    "easedesc4": "SPACE as the need for space for surveys and design is minimized",
    "easedesc5": "QUALITY for reaching the highest quality solution in the shortest time",
    "demandresponse": "Response to request",
    "counseling": "Consulting",

    "__What-We-Offer__":"Anasayfa Ne Sunuyoruz? için çeviri",
    "whatwe": "What We Offer",
    "whatwetitle": "Industrial designs for inventory management",
    "whatwedesc": "Using the power of technology, we offer our customers innovative solutions for packaging processes worldwide",
    "whatwequestitle": "Why inventory management?",
    "whatwequestdesc": "Inputs and outputs to your system are kept under control.",
    "whatwequestitle2": "Why should we improve packaging?",
    "whatwequestdesc2": "To save space, time, labor and unit part cost",
    "whatwequestitle3": "Why should we use different types of packaging?",
    "whatwequestdesc3": "Packaging should be designed according to the characteristics of the piece, the customer it reaches and the path it has traveled!",

    "__COUNTER__":"Anasayfa counter için çeviri",
    "counter1": "Successful delivery",
    "counter2": "Daily support",
    "counter3": "Happy Customers",
    "counter4": "Staff Members",

    "__ABOUT__":"Hakkımızda için çeviri",
    "namework": "Eksper Industrial",
    "aboutwork": "Company Founder",
    "aboutdesc": "In 1998, our working life adventure started in one of the largest automobile production factories of our country and in 2023, 25 years of knowledge and experience has been the main foundation of our company." ,
    "aboutdesc2": "As we accumulated knowledge and experience in production, quality, packaging, optimization and many more, we were excited to disseminate and share them with the entire sector years later. ",
    "aboutdesc3": "Nihayet 2023 yılında aynı amaca gönül vermiş çalışma arkadaşlarımızla beraber şirketimizi hayata geçirdik.",
    "aboutdesc4": "At first, our work remained within the organization, but later we had the opportunity to share information and hold working meetings with the supplier industry and other main industry organizations.",
    "aboutdesc5": "Nowadays, we are passionate about serving the industry in a wide range of areas, from the design and production of all kinds of packaging, the layout of production areas, the improvement of cycle times, sorting and retouching processes, to corrosion protection in transshipment.",
    "aboutdesc6":  "We enable companies to focus on their main production activities without wasting time on side jobs such as packaging, layout and optimization. With the many options we offer in every product range, we ensure that the best quality product is provided at the cheapest price and in the shortest time.",
    "aboutdesc7": "In short, we pave the way and you move forward.",
    "aboutexper": "Experience",
    
    "__CONTACT__":"İletişim için çeviri",
    "uscontact": "Contact Us",
    "message": "Leave a Message",
    "contactdesc": "Do you have a design idea for your needs? Let us support you!",
    "name":"Name",
    "email": "E-Mail",
    "phone": "Example '05559949808'",
    "subject": "Subject",
    "messagewrite": "Write a message...",
    "approval": "Confirm to be able to submit a form!",
    "formsend": "Send Message",
    "emailwrite": "Write Email",

    "__FAQ__":"SSS için çeviri",
    "sss": "Frequently Asked Questions",
    "faqtitle": "Why should we package our products?",
    "faqdesc": "To protect and transport the product and ensure the same quality for the end customer",
    "faqtitle2": "Which is the right packaging?",
    "faqdesc2": "The packaging that protects the product, withstands the journey and delivers the product to the end user is the right packaging!",
    "faqtitle3": "Why are there different types of packaging?",
    "faqdesc3": "Packaging can be made with a variety of materials to protect and transport the product in the quality in which it was produced and to offer it to the customer at the most cost-effective price. Wood for easy workability. Metal for its high carrying capacity. Cardboard packaging can be designed for its affordable price and light weight. In addition, the content of the packaging can be supported with different materials.",


    "__SERVICE-DESIGNER__":"Detaylı Ambalaj Tasarım için çeviri",
    "desginerdesc": "Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "desginerpros": "Design Processes",
    "designerans": "Recyclable or disposable packaging design.",
    "designerans2": "Cardboard, wood, plastic or metal packaging design in customized sizes.",
    "designerans3": "Catia drawing.",
    "designerans4": "Sample production.",

    "__SERVICE-ETUD__":"Detaylı Etüd için çeviri",
    "etüdDesc": "Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "etüdtitle": "Study Processes",
    "etüdans": "Packaging study for mass production factories.",
    "etüdans2": "Packaging study for out-of-series parts.",
    "etüdans3": "Packaging study according to mode of transportation (Land-Air-Sea).",
    "etüdans4": "Preparation of production receipts.",
    "etüdans5": "Costing of packaging.",
    "etüdans6": "Operation phases and time study.",
    "etüdans7": "Packaging with anti-corrosion protection.",

    "__SERVICE-PROCESS__":"Detaylı Proses için çeviri",
    "processdesc": "Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "processtitle": "Improvement Processes",
    "processans": "Post placement and improvement.",
    "processans2": "Organization of production flows.",
    "processans3": "Tape replenishment arrangement.",
    "processans4": "Change in processing sequence and mode of production.",
    "processans5": "Reducing production costs.",
    "processans6": "Elimination of packaging/quality problems.",
    "processans7": "Changing the packaging according to the change of transportation (Land-Air-Sea).",

    "__SERVICE-ROTUS__":"Detaylı Ayıklama & Rötüş için çeviri",
    "rotdesc": "Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "rotprotitle": "Sorting & Rotation Processes",
    "rotans": "Sorting as requested.",
    "rotans2": "Retouching as requested.",
    "rotans3": "Determination of the extraction and retouching method (with the approval of the Requester).",

    "__404__":"Detaylı 404 için çeviri",
    "page404title": "404 || Page Not Found",
    "404title": "Page is not found!",
    "lost": "Look like youre lost",
    "notavailble": "The page you are looking for not avaible!",
    "backhome": "Back to Home"

  };