import React from 'react'
import PageHeader from '../../PageHeader/pageheader'
import Study from '../servisedeatils/Study'
import { useTranslation } from 'react-i18next';
const ServiceStudy = () => {
  const { t } = useTranslation()
  return (
    <>
    
      <PageHeader title={t('home')} subtitle={t('pageheaderservice')} common={t('packetüd')}/>
        <Study />
    </>
  )
}

export default ServiceStudy