import React from 'react'
import PageHeader from '../../PageHeader/pageheader'
import PDesigner from '../servisedeatils/PDesigner'
import { useTranslation } from 'react-i18next';

const ServiceDesigner = () => {
  const { t } = useTranslation()
  return (
    <>
    
        <PageHeader title={t('home')} subtitle={t('pageheaderservice')} common={t('packdesigner')}/>
        <PDesigner/>
    </>
  )
}

export default ServiceDesigner