
import React from 'react';



function Gallery() {
    return (
        <>
            <section className="gallery-one">
                <div className="sw-container">
    </div>
            </section>
        </>
    )
}

export default Gallery