import React from 'react';
import ImageSlider from "./ImageSlider";


function Study() {

  const slides = [
    { url: "/a1-etüt.jpg", title: "Ambalaj Etüd" },
    { url: "/a2-etüt.jpg", title: "Ambalaj Etüd" },
    { url: "/a3-etüt.jpg", title: "Ambalaj Etüd" },
    { url: "/a5-etüt.jpg", title: "Ambalaj Etüd" },
  ];

  const containerStyles = {
    width: "350px",
    height: "350px",
    margin: "0 auto",
  };
    return (

      <div>
      <div style={containerStyles}>
      <ImageSlider slides={slides} parentWidth={500} />
      </div>
    </div>

    )
}

export default Study