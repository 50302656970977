import React from 'react'
import { Link}  from 'react-router-dom'
import tec from "../../assets/images/about/tecrübe.png"
import tecin from "../../assets/images/about/tecinf.png"
import { useTranslation } from 'react-i18next';

function First() {
    const { t } = useTranslation()
    return (
        <>
            <section className="team-details">
                <div className="container">
                        <div className="col-lg-12">
                            <div className="team-details__content">
                                <h2>{t('namework')}</h2>
                                <span>{t('aboutwork')}</span>
                                <p>{t('aboutdesc')}</p>
                                <p>{t('aboutdesc2')}</p>
                                <p>{t('aboutdesc3')}</p>
                                <p>{t('aboutdesc4')}</p>
                                <p>{t('aboutdesc5')}</p>
                                <p>{t('aboutdesc6')}</p>
                                <p>{t('aboutdesc7')}</p>

                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>{t('aboutexper')}</h3>
                                        <span>100%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '100%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
   
                                <div className="team-details__certificates">
                                    <img src={tec} width={75} alt="Eksper Endüstriyel" />
                                    <img src={tecin} width={75} alt="Eksper Endüstriyel" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="https://www.linkedin.com/company/eksperendustriyel/" className="fab fa-linkedin" rel="noreferrer" target="_blank"></Link>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </section>
        </>
    )
}

export default First